$fonthead: "Rubik", sans-serif;
$fontstack: "Rubik", sans-serif;
$colordark: #353353;
$coloryellow: #ffd15c;
$colorpink: #ff4c60;
$colorblue: #6c6ce5;
$colordefault: #454360;
$colorgreen: #44d7b6;

@mixin transition($second) {
  -webkit-transition: all $second ease-in-out;
  -moz-transition: all $second ease-in-out;
  transition: all $second ease-in-out;
}
